import React from "react";
import Tap from "./Tap";
import Header from "../Coman/Header";
import Roadmap from "./Roadmap";
import Whatis from "./Whatis";
import Howworks from "./Howworks";
import Eco from "./Eco";
import Min from "./Min";
import Whychoose from "./Whychoose";
import Join from "./Join";
import Footer from "../Coman/Footer";
import Threebox from "./Threebox";
import Timeplay from "./Timeplay";
import Phimg from "./Phimg";

export default function Index() {
  return (
    <>
      <Header />
      <Tap />
      <Whatis />
      <Threebox/>
      <Phimg/>
      <Howworks />
      <Eco />
      <Min />
      <Whychoose />
      <Roadmap />
      <Timeplay/>
      <Footer />
    </>
  );
}
