import React from 'react'

export default function () {
  return (
    <div>
        <div
  className="roadmap-area pd-top-120 pd-bottom-50 bg-cover"
  style={{ backgroundImage: 'url("assets/img/creator/bg2.png")' }}
>

  <div className="container">
    <h3 className='mb-4 text-center color-base'>Roadmap 
    </h3>
    <div className="row">
      
      <div className="col-lg-3 col-md-6 fade-slide bottom" data-delay="0.2">
        <div className="single-roadmap-inner">
          <img
            className="shape-img"
            src="assets/img/roadmap/bg.png"
            alt="img"
          />
          <div className="roadmap-content">
            <img
              className="main-img"
              src="assets/img/roadmap/1.svg"
              alt="img"
            />
            <h4 className='text-start'>Phase 1:
            Game Launch  & Community Building <br /><br /> </h4>
            <ul>
              <li>
                <span className="active" /> Launch of Minnion on Telegram with basic tap-to-earn features
              </li>
              <li>
                <span className="active" />Mining MIN tokens with player rewards.
              </li>
              {/* <li>
                <span className="active" /> iOS Open Beta
              </li>
              <li>
                <span className="active" /> Land Creation &amp; Building
              </li> */}
            </ul>
            <div className="text-start divide-shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="200px"
                height="3px"
              >
                <path
                  fillRule="evenodd"
                  fill="#0a0909"
                  d="M139.000,-0.002 C215.767,-0.002 278.000,0.670 278.000,1.498 C278.000,2.327 215.767,2.999 139.000,2.999 C62.232,2.999 -0.000,2.327 -0.000,1.498 C-0.000,0.670 62.232,-0.002 139.000,-0.002 Z"
                />
              </svg>
            </div>
            
            {/* <h4>Season 1</h4> */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 fade-slide bottom" data-delay="0.2">
        <div className="single-roadmap-inner">
          <img
            className="shape-img"
            src="assets/img/roadmap/bg.png"
            alt="img"
          />
          <div className="roadmap-content">
            <img
              className="main-img"
              src="assets/img/roadmap/1.svg"
              alt="img"
            />
            <h4 className='text-start'>Phase 2: Expansion & Features <br /></h4>
            <ul>
              <li>
                <span className="active" />Introduction of in-game purchases and character customizations using MIN tokens.
              </li>
              <li>
                <span className="active" />Expanding the game mechanics and introducing competitive leaderboards.
              </li>
              {/* <li>
                <span className="active" /> iOS Open Beta
              </li>
              <li>
                <span className="active" /> Land Creation &amp; Building
              </li> */}
            </ul>
            <div className="text-start divide-shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="200px"
                height="3px"
              >
                <path
                  fillRule="evenodd"
                  fill="#0a0909"
                  d="M139.000,-0.002 C215.767,-0.002 278.000,0.670 278.000,1.498 C278.000,2.327 215.767,2.999 139.000,2.999 C62.232,2.999 -0.000,2.327 -0.000,1.498 C-0.000,0.670 62.232,-0.002 139.000,-0.002 Z"
                />
              </svg>
            </div>
            
            {/* <h4>Season 1</h4> */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 fade-slide bottom" data-delay="0.2">
        <div className="single-roadmap-inner">
          <img
            className="shape-img"
            src="assets/img/roadmap/bg.png"
            alt="img"
          />
          <div className="roadmap-content">
            <img
              className="main-img"
              src="assets/img/roadmap/1.svg"
              alt="img"
            />
            <h4 className='text-start'>Phase 3: Staking and Token Economy <br /><br />   </h4>
            
            <ul>
              <li>
                <span className="active" /> Launch staking functionalities to reward loyal players.
              </li>
              <li>
                <span className="active" />Further development of trading options for MIN tokens.
              </li>
              {/* <li>
                <span className="active" /> iOS Open Beta
              </li>
              <li>
                <span className="active" /> Land Creation &amp; Building
              </li> */}
            </ul>
            <div className="text-start divide-shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="200px"
                height="3px"
              >
                <path
                  fillRule="evenodd"
                  fill="#0a0909"
                  d="M139.000,-0.002 C215.767,-0.002 278.000,0.670 278.000,1.498 C278.000,2.327 215.767,2.999 139.000,2.999 C62.232,2.999 -0.000,2.327 -0.000,1.498 C-0.000,0.670 62.232,-0.002 139.000,-0.002 Z"
                />
              </svg>
            </div>
            
            {/* <h4>Season 1</h4> */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 fade-slide bottom" data-delay="0.2">
        <div className="single-roadmap-inner">
          <img
            className="shape-img"
            src="assets/img/roadmap/bg.png"
            alt="img"
          />
          <div className="roadmap-content">
            <img
              className="main-img"
              src="assets/img/roadmap/1.svg"
              alt="img"
            />
            <h4 className='text-start'>Phase 4: Community Events and Partnerships <br /></h4>
            <ul>
              <li>
                <span className="active" />Collaborations with other Telegram games and P2E ecosystems.
              </li>
              <li>
                <span className="active" />Launch community-driven events with special rewards and airdrops.
              </li>
              {/* <li>
                <span className="active" /> iOS Open Beta
              </li>
              <li>
                <span className="active" /> Land Creation &amp; Building
              </li> */}
            </ul>
            <div className="text-start divide-shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="200px"
                height="3px"
              >
                <path
                  fillRule="evenodd"
                  fill="#0a0909"
                  d="M139.000,-0.002 C215.767,-0.002 278.000,0.670 278.000,1.498 C278.000,2.327 215.767,2.999 139.000,2.999 C62.232,2.999 -0.000,2.327 -0.000,1.498 C-0.000,0.670 62.232,-0.002 139.000,-0.002 Z"
                />
              </svg>
            </div>
            
            {/* <h4>Season 1</h4> */}
          </div>
        </div>
      </div>
   
    </div>
  </div>
</div>

    </div>
  )
}
