import React from 'react'

export default function Tap() {
  return (
    <div>
        <div
  className="banner-area bg-relative banner-area-1"
  // style={{ backgroundImage: 'url("assets/img/banner/3.png")' }}
>
  <img className="chain-style" src="assets/img/banner/5.png" alt="img" />
  <img
    className="chain-style right-to-left-move"
    src="assets/img/banner/5.png"
    alt="img"
  />
  <div className="container">
    <div className="row">
      <div className="col-lg-7">
        <div className="banner-content">
          <h1 className="title move-line-3d">
            <span className="light">
              Tap into Fun

            </span>
            <span className="font-large">
             & <span className="color-base">Earn  </span>
            </span>
            <span className="light"> </span>
            <span className="color-base">MIN Tokens!</span>
          </h1>
          <h4 className="sub-title split_chars" style={{fontWeight:"400"}}>Welcome to the world of <b>Minnions🤩</b> </h4>
          <div className="btn-box d-inline-block fade-slide bottom">
            {/* <a className="btn btn-main style-small" href="#">
              <span>
                <span>Join Now </span>
              </span>
            </a> */}
          </div>

          <div className="btn-box d-inline-block fade-slide bottom mt-3 ">
            {/* <a className="btn btn-main style-small" href="#">
              <span>
                <span>Whitepaper </span>
              </span>
            </a> */}
                 <button class="btn3  px-4 py-2 m-2">Join Now  </button>
                 <button class="btn3  px-4 py-2 m-2">Whitepaper  </button>
          </div>


          <div className="list-img fade-slide bottom d-flex" data-delay="0.8">
            <h5 >Powered By : </h5>
            <img src="assets/newimg/ton.svg" alt="img"  className='img-fluid  mx-4' width={72}/>
            <img src="assets/newimg/telegram.svg" alt="img" className='img-fluid' width={100}/>
          </div>
        </div>
      </div>
      <div className="col-lg-5 mt-lg-0 mt-5">
        <div className="banner-img text-lg-end top_image_bounce">
          
          <img src="assets/newimg/hero.png" alt="img"  style={{zIndex:"9999",position:"relative"}}/>
          <img
            className="shape-img  shapeBlinker_img" style={{zIndex:"9"}}
            src="assets/newimg/herolight.png"
            alt="img"
          />
          <img
          // spin_image
            className="shape-img-2 "
            src="assets/newimg/hero02.png"
            style={{top:"-90px",left:"-16px"}}
            alt="img"
          />
          <img
          // spin_image
            className=" hero-shap-1"
            src="assets/newimg/hero01.png"
            // style={{top:"-90px",left:"-16px"}}
            alt="img"
          />
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
