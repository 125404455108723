import React from 'react'

export default function Threebox() {
  return (
    <>
     <div class=" pd-top-120 pd-bottom-10">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    {/* <div class="section-title text-center">
                        <h6 class="subtitle color-base split_chars">Our Tournaments</h6>
                        <h2 class="title move-line-3d">The VICTORY POINT</h2>
                        <img class="mt-3" src="assets/img/icon/shalep-1.png" alt="img"/>
                    </div> */}
                </div>
            </div>
            <div class="single-tournament-inner fade-slide bottom" data-delay="0.2">
                <img class="bg-one" src="assets/img/tournament/bg.png" alt="img"/>
                <img class="bg-two" src="assets/img/tournament/bg-2.png" alt="img"/>
                <div class="row">
                    <div class="col-lg-4 align-self-center">
                    <img src="assets/newimg/playing_games.png" alt=""  style={{position:"relative",left:"90px",}}/>

                    {/* <img src="assets/newimg/01img.jpg" alt="img" style={{height:"200px",width:"200px",position: "relative",bottom: "20px",
    left: "50px"}}/>   */}

                        {/* <div class="left-content">
                            <h6>Sky Hunetr</h6>
                            <h4 class="location">Venues Dubai</h4>
                        </div> */}
                    </div>
                    <div class="col-lg-8">
                        <div class="tournament-inner-wrap">
                            <h5 class="date text-center"></h5>
                            <div class="tournament-inner d-flex justify-content-between">
                                 <div class="team-left d-flex align-item-center align-items-center gap-md-5 gap-3">
                                    <h2 class="score text pt-">Want to earn crypto by  playing games?</h2>
                                </div> 
                                {/* <div class="middle-wrap align-self-center">
                                    <h3 class="tournament-beat">VS</h3>
                                </div> */}
                                {/* <div class="team-right d-flex align-item-center align-items-center gap-md-5 gap-3">
                                    <img src="assets/img/tournament/2.png" alt="img"/>
                                    <h2 class="score">0</h2>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single-tournament-inner fade-slide bottom" data-delay="0.3">
                <img class="bg-one" src="assets/img/tournament/bg.png" alt="img"/>
                <img class="bg-two" src="assets/img/tournament/bg-2.png" alt="img"/>
                <div class="row">
                    <div class="col-lg-4 align-self-center">
                        <img src="assets/newimg/dont_way_home.png" alt=""  style={{position:"relative",left:"90px",}}/>
                    {/* <img src="assets/newimg/02.jpg" alt="img"style={{height:"200px",width:"200px",position: "relative",bottom: "20px",
    left: "50px"}}/>   */}

                        {/* <div class="left-content">
                            <h6>Sky Hunetr</h6>
                            <h4 class="location">Venues Dubai</h4>
                        </div> */}
                    </div>
                    <div class="col-lg-8">
                        <div class="tournament-inner-wrap">
                            {/* <h5 class="date text-center">August 19, 2023, 8:30 PM</h5> */}
                            <div class="tournament-inner d-flex justify-content-between">
                               <div class="team-left d-flex align-item-center align-items-center gap-md-5 gap-3">
                                    <h4 class="score py-5">Don't know what to do on  your way home?</h4>
                                </div>
                                {/* <div class="middle-wrap align-self-center">
                                    <h3 class="tournament-beat">Don't know what to do on  your way home?</h3>
                                </div> */}
                                {/* <div class="team-right d-flex align-item-center align-items-center gap-md-5 gap-3">
                                    <img src="assets/img/tournament/4.png" alt="img"/>
                                    <h2 class="score">0</h2>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single-tournament-inner fade-slide bottom" data-delay="0.4">
                <img class="bg-one" src="assets/img/tournament/bg.png" alt="img"/>
                <img class="bg-two" src="assets/img/tournament/bg-2.png" alt="img"/>
                <div class="row">
                    <div class="col-lg-4 align-self-center">
                    <img src="assets/newimg/relax.png" alt=""  style={{position:"relative",left:"90px",}}/>

                    {/* <img src="assets/newimg/03.jpg" alt="img"style={{height:"200px",width:"200px",position: "relative",bottom: "20px",
    left: "50px"}}/>   */}

                        {/* <div class="left-content">
                            <h6>Sky Hunetr</h6>
                            <h4 class="location">Venues Dubai</h4>
                        </div> */}
                    </div>
                    <div class="col-lg-8">
                        <div class="tournament-inner-wrap">
                            {/* <h5 class="date text-center">August 19, 2023, 8:30 PM</h5> */}
                            <div class="tournament-inner d-flex justify-content-between">
                                <div class="team-left d-flex align-item-center align-items-center gap-md-5 gap-3">
                                    <h2 class="score pt-5">Want to  turn off your head and relax?</h2>
                                </div>
                                {/* <div class="middle-wrap align-self-center">
                                    <h3 class="tournament-beat">VS</h3>
                                </div> */}
                                {/* <div class="team-right d-flex align-item-center align-items-center gap-md-5 gap-3">
                                    <img src="assets/img/tournament/6.png" alt="img"/>
                                    <h2 class="score">0</h2>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-5">
                {/* <div class="btn-box d-block">
                    <a class="btn btn-main style-small" href="#">
                       <span>
                            <span>View More</span>
                        </span> 
                    </a>
                </div> */}
            </div>
        </div>
    </div>
    </>
  )
}
