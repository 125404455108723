import React from "react";

export default function Min() {
  return (
    <div>
      <div className="about-area position-relative pd-top-0 pd-bottom-70">
        <div className="container">
     
          <div className="row">
            <div className="col-xl-7 col-lg-10 ps-xl-5 order-lg-last">
              <div
                className="about-thumb-inner position-relative mb-4 text-center fade-slide bottom"
                data-delay="0.5"
              >
                <img src="assets/newimg/MIN-Token-Section-Image.png" className="img-fluid" alt="img" />
               
                {/* <img
                  className="animate-top-left top_image_bounce"
                  src="assets/img/about/10.png"
                  alt="img"
                /> */}
              </div>
            </div>
            <div className="col-xl-5 align-self-center order-lg-start">
              <div className="about-content section-title mt-5 mt-xl-0 mb-0">
                {/* <h6 className="sub-title split_chars">
            Our Roadmap{" "}
            <img
              className="ms-2"
              src="assets/img/icon/shalep-1.png"
              alt="img"
            />
          </h6> */}
                <h2 className="title move-line-3d">MIN <span className="color-base"> Token</span> </h2>
                <p className="content fade-slide bottom" data-delay="0.2">
                  MIN is the native token for Minnions game that serve as the
                  in-game currency! It is earned by players through gameplay and
                  can be used for various purposes within the Minnion ecosystem!{" "}
                  <br />
                  <br />
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle me-2 text-warning "
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>{" "}
                    Player Rewards
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle me-2 text-warning "
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                    In-game Currency
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle me-2 text-warning "
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                    Staking & Trading
                  </p>
                </p>
                {/* <div
            className="small-title-2 pb-4 fade-slide bottom"
            data-delay="0.4"
          >
            <img src="assets/img/about/2.png" alt="img" /> | Earn Great Rewards{" "}
            <span className="color-base">( Friend )</span>
          </div> */}
                {/* <img src="assets/img/shape/2.png" alt="img" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
