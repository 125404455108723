import React from "react";

export default function Header() {
  return (
    <div>
      <nav className="navbar navbar-area navbar-area-1 navbar-border navbar-expand-lg">
        <div className="container nav-container px-lg-0">
          {/* <div className="responsive-mobile-menu">
            <button
              className="menu toggle-btn d-block d-lg-none"
              data-target="#xdyat"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div> */}
          <div className="logo">
            <a href="#">
              <img src="assets/newimg/logo.svg" alt="img" width={200} />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="xdyat_main_menu"></div>
          <div className="nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center">
            <div className=" d-inline-block">
              {/* <a className="btn btn-main style-small" href="#">
                <span>
                  <span></span>
                  <span>Launch App</span>
                </span>
              </a> */}
              	<button class="btn1 px-3  px-md-5">Launch App</button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
