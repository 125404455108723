import React from 'react'

export default function () {
  return (
    <div className="about-area position-relative pd-top-20 pd-bottom-30">
  <div className="container">
    <div className="text-center mb-5 fade-slide bottom" data-delay="0.2">
      {/* <img src="assets/img/about/8.png" alt="img" /> */}
    </div>
    {/* <div className="intro-box-wrap gap-3 mb-5">
      <div
        className="intro-box-inner text-center fade-slide bottom"
        data-delay="0.3"
      >
        <div className="icon">
          <img src="assets/img/icon/1.png" alt="img" />
        </div>
        <div className="content">
          <h4>Come Play</h4>
        </div>
      </div>
      <div
        className="intro-box-inner text-center fade-slide bottom"
        data-delay="0.4"
      >
        <div className="icon">
          <img src="assets/img/icon/2.png" alt="img" />
        </div>
        <div className="content">
          <h4>Gome Play</h4>
        </div>
      </div>
      <div
        className="intro-box-inner text-center fade-slide bottom"
        data-delay="0.5"
      >
        <div className="icon">
          <img src="assets/img/icon/3.png" alt="img" />
        </div>
        <div className="content">
          <h4>Well Play</h4>
        </div>
      </div>
      <div
        className="intro-box-inner text-center fade-slide bottom"
        data-delay="0.6"
      >
        <div className="icon">
          <img src="assets/img/icon/4.png" alt="img" />
        </div>
        <div className="content">
          <h4>Come Play</h4>
        </div>
      </div>
      <div
        className="intro-box-inner text-center fade-slide bottom"
        data-delay="0.7"
      >
        <div className="icon">
          <img src="assets/img/icon/5.png" alt="img" />
        </div>
        <div className="content">
          <h4>Feature</h4>
        </div>
      </div>
    </div> */}
    <div className="row">
      <div className="col-xl-7 col-lg-10 ps-xl-5 order-lg-last">
        <div
          className="about-thumb-inner position-relative mb-4 text-lg-end fade-slide bottom"
          data-delay="0.5"
        >
          <img src="assets/newimg/Level-up-your-character-Section-Image.png" alt="img" />
          {/* <img
            className="animate-top-left top_image_bounce"
            src="assets/img/about/10.png"
            alt="img"
          /> */}
        </div>
      </div>
      <div className="col-xl-5 align-self-center order-lg-start">
        <div className="about-content section-title mt-5 mt-xl-0 mb-0">
          {/* <h6 className="sub-title split_chars">
            Our Roadmap{" "}
            <img
              className="ms-2"
              src="assets/img/icon/shalep-1.png"
              alt="img"
            />
          </h6> */}
          <h1 className="move-line-3d" style={{color:"#fde93b"}}>
          Level up your  <br />
          character
          </h1>
          <p className="content fade-slide bottom" data-delay="0.2" >
          <h4 className='ttp' style={{fontWeight:"300"}}>Tap the character, buy boosts, and track the leaderboard</h4>
          </p>
          {/* <div
            className="small-title-2 pb-4 fade-slide bottom"
            data-delay="0.4"
          >
            <img src="assets/img/about/2.png" alt="img" /> | Earn Great Rewards{" "}
            <span className="color-base">( Friend )</span>
          </div> */}
          {/* <img src="assets/newimg/MIN-Token-Section-Image.png" alt="img" /> */}
        </div>
      </div>
    </div>
  </div>





  <div className="about-area position-relative pd-top-120 pd-bottom-70">
  <div className="container">
    <div className="game-image-list mb-5">
      {/* <div className="row">
        <div
          className="col-lg-2 col-md-4 col-sm-6 mb-3 fade-slide bottom"
          data-delay="0.2"
        >
          <div className="image-list-inner text-center">
            <img src="assets/img/about-icon-img/1.png" alt="img" />
          </div>
        </div>
        <div
          className="col-lg-2 col-md-4 col-sm-6 mb-3 fade-slide bottom"
          data-delay="0.3"
        >
          <div className="image-list-inner text-center">
            <img src="assets/img/about-icon-img/2.png" alt="img" />
          </div>
        </div>
        <div
          className="col-lg-2 col-md-4 col-sm-6 mb-3 fade-slide bottom"
          data-delay="0.4"
        >
          <div className="image-list-inner text-center">
            <img src="assets/img/about-icon-img/3.png" alt="img" />
          </div>
        </div>
        <div
          className="col-lg-2 col-md-4 col-sm-6 mb-3 fade-slide bottom"
          data-delay="0.5"
        >
          <div className="image-list-inner text-center">
            <img src="assets/img/about-icon-img/4.png" alt="img" />
          </div>
        </div>
        <div
          className="col-lg-2 col-md-4 col-sm-6 mb-3 fade-slide bottom"
          data-delay="0.6"
        >
          <div className="image-list-inner text-center">
            <img src="assets/img/about-icon-img/5.png" alt="img" />
          </div>
        </div>
        <div
          className="col-lg-2 col-md-4 col-sm-6 mb-3 fade-slide bottom"
          data-delay="0.7"
        >
          <div className="image-list-inner text-center">
            <img src="assets/img/about-icon-img/6.png" alt="img" />
          </div>
        </div>
      </div> */}
    </div>
    <div className="row">
      <div className="col-xl-7 col-lg-10 pe-xl-5">
        <div
          className="about-thumb-inner mb-4 fade-slide right"
          data-delay="0.2"
        >
          <img src="assets/img/crypto.png" alt="img" />
        </div>
      </div>
      <div className="col-xl-5 align-self-center">
        <div className="about-content section-title mt-5 mt-xl-0 mb-0">
          <h1 className=" move-line-3d" style={{color:"#fde93b"}}>MIN crypto in the wallet</h1>
          {/* <h5 className="small-title split_chars">
            arctic warfare magnum {"{"}nft’s{"}"}
            <span className="right-shape float-lg-end">
              <img src="assets/img/shape/1.png" alt="img" />
            </span>
          </h5> */}
          <p className="content fade-slide bottom" data-delay="0.3">
          <h4 lassName='ttp' style={{fontWeight:"300"}}>Swap $MIN for USDT and TON within the game</h4>
          </p>
          {/* <div className="small-title-2 fade-slide bottom" data-delay="0.5">
            <img src="assets/img/about/2.png" alt="img" /> | Earn Great Rewards{" "}
            <span className="color-base">( Friend )</span>
          </div> */}
          {/* <div
            className="btn-box d-inline-block fade-slide bottom"
            data-delay="0.7"
          >
            <a className="btn btn-main style-small" href="about.html">
              <span>
                <span>Explore More</span>
              </span>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</div> 

</div>








  )
}
