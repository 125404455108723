import React from "react";

export default function Whychoose() {
  return (
    <div>
      <div>
        <div className="testimonial-area pd-top-20">
          <div className="container">

            <div className="section-title">
              
              <div className="row">
                <div className="col-lg-12">
                <img src="assets/newimg/02_minnion.png" className='img-3-roted d-none d-lg-block top_image_bounce' alt="img" />

                  {/* <h6 className="sub-title split_chars">Our Testimonials</h6> */}
                  <h2 className="title move-line-3d ">
                    Why Choose <span> Minnion? </span>
                  </h2>
                </div>
                <div className="col-lg-6 d-lg-block d-none">
                  <div className="array-button slider-control-round  text-lg-end">
                    {/* <button className="array1-prev me-0">
              <i className="fa fa-angle-left" />
            </button>
            <button className="array1-next">
              <i className="fa fa-angle-right" />
            </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper mySwiper2">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="feedback-inner mb-5">
                    {/* <img src="assets/img/testimonial/1.png" alt="img" /> */}
                    <h4
                        className="title text-white mb-0"
                       
                      >
                        {" "}
                        Easy Access
                      </h4>{" "}
                    <p className="mb-3">
                     
                      No need for fancy hardware or software-just tap your
                      screen and start mining MIN tokens!
                    </p>
                    <h4
                        className="title text-white mb-0"
                      >
                        {" "}
                        Engaging Gameplay 
                      </h4>{" "}
                    <p className="mb-3">
                      
                      Experience the thrill of earning real-time rewards as you progress through the game.

                    </p>
                    <h4
                        className="title text-white mb-0"
                      >
                        {" "}
                        Inclusive for All 
                      </h4>{" "}
                    <p className="mb-3">
                    
                      Whether you’re a casual player or a dedicated gamer, anyone can join the fun and earn in the Minnion ecosystem.

                    </p>
                  </div>
                </div>
                {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
                {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
                {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
                {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
                {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
              </div>
            </div>
            {/* <div className="swiper mySwiper feedback-list-slider">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/2.png" alt="img" />
            <div className="details">
              <h5>Alaxerder “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/3.png" alt="img" />
            <div className="details">
              <h5>Jemse lee “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/4.png" alt="img" />
            <div className="details">
              <h5>pall waker “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/5.png" alt="img" />
            <div className="details">
              <h5>Tonoy pro “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/2.png" alt="img" />
            <div className="details">
              <h5>Alaxerder “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/3.png" alt="img" />
            <div className="details">
              <h5>Jemse lee “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
      </div>
    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
