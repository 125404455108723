import React from 'react'

export default function () {
  return (
    <div>
        <div className="testimonial-area pd-top-60">
  <div className="container">
    <div className="section-title">
        {/* <img src="assets/newimg/03_minnion.png" style={{width:"120px",zIndex:"999",top:"-100px"}} className='img-2-roted d-none d-lg-block top_image_bounce' alt="img" /> */}
      <div className="row">
        <div className="col-lg-12">

          {/* <h6 className="sub-title split_chars">Our Testimonials</h6> */}
          <h2 className="title move-line-3d text-center">
          The Minnion <span className='color-base'> Ecosystem </span>
          </h2>
        </div>
        <div className="col-lg-6 d-lg-block d-none">
          <div className="array-button slider-control-round  text-lg-end">
            {/* <button className="array1-prev me-0">
              <i className="fa fa-angle-left" />
            </button>
            <button className="array1-next">
              <i className="fa fa-angle-right" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
    <div className="swiper mySwiper2">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="feedback-inner mb-5">
            {/* <img src="assets/img/testimonial/1.png" alt="img" /> */}
            <p>
            Minnion is not just a game; it's a thriving decentralized economy that merges entertainment with decentralized finance (DeFi). As you play, you’ll unlock various features, staking opportunities, and new gameplay mechanics, continuously evolving the Minnion universe.
            </p>
          </div>
        </div>
        {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
        {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
        {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
        {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
        {/* <div className="swiper-slide">
          <div className="feedback-inner">
            <img src="assets/img/testimonial/1.png" alt="img" />
            <p>
              Building your own stuff, you can do that too. The ASSET CREATOR
              will allow creators to make things for the as well as sell.
            </p>
          </div>
        </div> */}
      </div>
    </div>
    {/* <div className="swiper mySwiper feedback-list-slider">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/2.png" alt="img" />
            <div className="details">
              <h5>Alaxerder “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/3.png" alt="img" />
            <div className="details">
              <h5>Jemse lee “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/4.png" alt="img" />
            <div className="details">
              <h5>pall waker “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/5.png" alt="img" />
            <div className="details">
              <h5>Tonoy pro “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/2.png" alt="img" />
            <div className="details">
              <h5>Alaxerder “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="feedback-list">
            <img src="assets/img/testimonial/3.png" alt="img" />
            <div className="details">
              <h5>Jemse lee “</h5>
              <span>Game CEO</span>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </div>
</div>

    </div>
  )
}
