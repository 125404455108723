import React from 'react'

export default function () {
  return (
    <div>
        <div className="position-relative pd-top-10 pd-bottom-40">
  <div className="container">
    <div className="row">
      
      <div className="col-lg-12 text-center align-self-center">
      <img src="assets/newimg/01_minnion.png" style={{width:"150px"}} className='img-1-roted d-none d-lg-block shapeBlinker_img' alt="img" />

        <div className="about-content section-title mt-5 mt-xl-0 mb-lg-0 mb-5">
          {/* <h6 className="sub-title-2">
            Gaming Platform
            <span />
            <span />
            <span />
            <span />
          </h6> */}
          <h2 className="title mb-4">
          How It  <span className='color-base'> Works</span> 
          </h2>
          {/* <div className="small-title-2 mb-4">
            <img src="assets/img/about/2.png" alt="img" /> | Earn Great Rewards{" "}
            <span className="color-base">( Friend )</span>
          </div> 
          <div className="btn-box d-inline-block">
            <a className="btn btn-main style-small" href="about.html">
              <span>
                <span>Explore More</span>
              </span>
            </a>
          </div>*/}
        </div>
      </div>
      <div className="col-lg-6 pe-xl-5">
        {/* <div className="single-platform-inner">
          <img className="bg-image" src="assets/img/bg/2.png" alt="img" />
          <div className="details-inner">
            <a className="read-more text-end d-block" href="wallet.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="22px"
                height="22px"
              >
                <image
                  x="0px"
                  y="0px"
                  width="22px"
                  height="22px"
                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEX///9NTk3ExMSIiYj////h4eGmpqb///9/FIABAAAAB3RSTlMAHJNWza90vjA1FgAAAAFiS0dEAIgFHUgAAAAHdElNRQfoCB8EMzmirnqjAAAAbElEQVQY00XNwQmAMAyF4UcVz+IEIl1AEJygeNUNREq9iyXr29SmeaeP/5AAwDDxRvBW4rmevWTTwe5CCBfRgzJDFMVWbTZ3i+27i1OGOGUxZzHn4pyLc/79Z/hYM5q5Zj2iL2VeM1rNwFn1Aeb+IAGtAecIAAAAAElFTkSuQmCC"
                />
              </svg>
            </a>
            <div className="icon">
              <img src="assets/img/platform/1.png" alt="img" />
            </div>
            <a href="#" className="cat">
              <i className="fa fa-bookmark" />
              Developer
            </a>
            <h3 className="title">
              Crypto <span>Ownership</span>
            </h3>
          </div>
        </div> */}
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-lg-4">
        <div className="single-platform-inner style-2">
          <img className="bg-image" src="assets/img/bg/1.png" alt="img" />
          <div className="details-inner">
            {/* <a className="read-more text-end d-block" href="wallet.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="22px"
                height="22px"
              >
                <image
                  x="0px"
                  y="0px"
                  width="22px"
                  height="22px"
                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEX///9NTk3ExMSIiYj////h4eGmpqb///9/FIABAAAAB3RSTlMAHJNWza90vjA1FgAAAAFiS0dEAIgFHUgAAAAHdElNRQfoCB8EMzmirnqjAAAAbElEQVQY00XNwQmAMAyF4UcVz+IEIl1AEJygeNUNREq9iyXr29SmeaeP/5AAwDDxRvBW4rmevWTTwe5CCBfRgzJDFMVWbTZ3i+27i1OGOGUxZzHn4pyLc/79Z/hYM5q5Zj2iL2VeM1rNwFn1Aeb+IAGtAecIAAAAAElFTkSuQmCC"
                />
              </svg>
            </a>
            <div className="icon">
              <img src="assets/img/platform/2.png" alt="img" />
            </div>
            <a href="#" className="cat">
              <i className="fa fa-bookmark" />
              Developer
            </a> */}
            <h3 className="title">
            Start <span>Tapping </span>
            </h3>
            <h5 className='ttp' style={{fontWeight:"300"}}>Simply tap your screen to begin mining MIN tokens. The more you tap, the more you earn!
            </h5>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="single-platform-inner style-2">
          <img className="bg-image" src="assets/img/bg/1.png" alt="img" />
          <div className="details-inner">
            {/* <a className="read-more text-end d-block" href="wallet.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="22px"
                height="22px"
              >
                <image
                  x="0px"
                  y="0px"
                  width="22px"
                  height="22px"
                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEX///9NTk3ExMSIiYj////h4eGmpqb///9/FIABAAAAB3RSTlMAHJNWza90vjA1FgAAAAFiS0dEAIgFHUgAAAAHdElNRQfoCB8EMzmirnqjAAAAbElEQVQY00XNwQmAMAyF4UcVz+IEIl1AEJygeNUNREq9iyXr29SmeaeP/5AAwDDxRvBW4rmevWTTwe5CCBfRgzJDFMVWbTZ3i+27i1OGOGUxZzHn4pyLc/79Z/hYM5q5Zj2iL2VeM1rNwFn1Aeb+IAGtAecIAAAAAElFTkSuQmCC"
                />
              </svg>
            </a> */}
            {/* <div className="icon">
              <img src="assets/img/platform/2.png" alt="img" />
            </div>
            <a href="#" className="cat">
              <i className="fa fa-bookmark" />
              Developer
            </a> */}
            <h3 className="title">
            Strategic <span>Play</span>
            </h3>
            <h5 className='ttp' style={{fontWeight:"300"}}> 
            Aim for higher rewards through consistent gameplay and smart strategies to maximize your earnings.</h5>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="single-platform-inner style-2">
          <img className="bg-image" src="assets/img/bg/1.png" alt="img" />
          <div className="details-inner">
            {/* <a className="read-more text-end d-block" href="wallet.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="22px"
                height="22px"
              >
                <image
                  x="0px"
                  y="0px"
                  width="22px"
                  height="22px"
                  xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEX///9NTk3ExMSIiYj////h4eGmpqb///9/FIABAAAAB3RSTlMAHJNWza90vjA1FgAAAAFiS0dEAIgFHUgAAAAHdElNRQfoCB8EMzmirnqjAAAAbElEQVQY00XNwQmAMAyF4UcVz+IEIl1AEJygeNUNREq9iyXr29SmeaeP/5AAwDDxRvBW4rmevWTTwe5CCBfRgzJDFMVWbTZ3i+27i1OGOGUxZzHn4pyLc/79Z/hYM5q5Zj2iL2VeM1rNwFn1Aeb+IAGtAecIAAAAAElFTkSuQmCC"
                />
              </svg>
            </a> */}
            {/* <div className="icon">
              <img src="assets/img/platform/3.png" alt="img" />
            </div>
            <a href="#" className="cat">
              <i className="fa fa-bookmark" />
              Developer
            </a> */}
            <h3 className="title">
            Join the <span>Community</span>
            </h3>
            <h5 className='ttp' style={{fontWeight:"300"}}>Join the Community
Connect with fellow players, share tips, and participate in community-driven events to enhance your experience.
</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<img className="chain-style" src="assets/img/banner/5.png" alt="img" />

    </div>
  )
}
