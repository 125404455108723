import React from 'react'

export default function () {
  return (
    <div>
       <div>
        <div
  className="about-area position-relative bg-cover pd-top-120 pd-bottom-70"
  // style={{ backgroundImage: 'url("assets/img/about/bg.png")' }}
>
  <div className="container">
    <div className="row">
      <div className="col-xl-7 col-lg-10 pe-xl-5">
        <div
          className="about-thumb-inner mb-4 fade-slide right"
          data-delay="0.2"
        >
          <img src="assets/newimg/what-is-minnion-section-image-file.png" alt="img" />
        </div>
      </div>
      <div className="col-xl-5 align-self-center">
        <div className="about-content section-title mt-lg-5 mt-xl-0 mb-0">
          <h2 className="title move-line-3d">What is <span className='color-base'> Minnion?</span> 
          </h2>
          {/* <h5 className="small-title split_chars">
            arctic warfare magnum {"{"}nft’s{"}"}
          </h5> */}
          <p className="content fade-slide bottom" data-delay="0.3">
          Minnion is a tap-to-earn game that allows users to mine MIN tokens effortlessly through a simple tapping mechanic. Forget about complex downloads or installations; with over a billion users on Telegram, Minnion leverages this established platform to deliver an enjoyable and seamless gaming experience.
          </p>
          {/* <div className="small-title-2 fade-slide bottom" data-delay="0.5">
            <img src="assets/img/about/2.png" alt="img" /> | Earn Great Rewards{" "}
            <span className="color-base">( Friend )</span>
          </div> */}
          {/* <div
            className="btn-box d-inline-block fade-slide bottom"
            data-delay="0.7"
          >
            <a className="btn btn-main style-small" href="about.html">
              <span>
                <span>Explore More</span>
              </span>
            </a>
          </div> */}
        </div>
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-3 col-sm-6">
        <div
          className="game-single-level text-center p-4 fade-slide bottom"
          data-delay="0.2"
        >
          <img src="assets/newimg/Tap-&-Playgame.png" alt="img" />
          <h4 className="tt-uppercase mt-3 mb-3">Tap  &amp;  Playgame</h4>
          <p>Simply tap to start earning MIN tokens—fun and rewards at your fingertips!</p>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div
          className="game-single-level text-center p-4 fade-slide bottom"
          data-delay="0.3"
        >
          <img src="assets/newimg/Earn-Bonus.png" alt="img" />
          <h4 className="tt-uppercase mt-3 mb-3">Earn Bonus</h4>
          <p>Boost your rewards with exciting bonuses for consistent gameplay. </p>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div
          className="game-single-level text-center p-4 fade-slide bottom"
          data-delay="0.4"
        >
          <img src="assets/newimg/Refer-People.png" alt="img" />
          <h4 className="tt-uppercase mt-3 mb-3">Refer People</h4>
          <p>Invite friends to join Minnion and earn extra MIN tokens together.</p>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div
          className="game-single-level text-center p-4 fade-slide bottom"
          data-delay="0.5"
        >
          <img src="assets/newimg/Utilize-Tokens.png" alt="img" />
          <h4 className="tt-uppercase mt-3 mb-3">Utilize Tokens</h4>
          <p> Use your MIN tokens for in-game rewards, staking, and more in the Minnion ecosystem!</p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    </div>
  )
}

